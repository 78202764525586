var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"d-flex",attrs:{"value":_vm.dialog,"width":"550px","persistent":""}},[_c('v-card',{staticClass:"dialog-content px-4 py-2"},[_c('v-card-title',{staticClass:"title mb-1 pa-4"},[_vm._v("Escolha uma das opções abaixo")]),_c('v-card-actions',{staticClass:"d-flex justify-end align-self-end"},[_c('v-row',[_c('v-card-text',{staticClass:"pt-0"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.integratorTypes),function(type,index){return _c('v-flex',{key:(index + "-" + (type.text)),attrs:{"xs6":"","md6":""}},[_c('type-card',{attrs:{"text":type.text,"description":type.description,"sales-type":type.integratorType,"selected":_vm.selectedIntegrator},on:{"update:selected":function($event){_vm.selectedIntegrator=$event}}})],1)}),1)],1)],1)],1)],1),(_vm.error)?_c('p',{staticClass:"ma-0 pl-3 error-message"},[_vm._v("Selecione uma modalidade")]):_vm._e(),_c('mf-action-buttons',{staticClass:"mr-1",attrs:{"primary-button":{
        text: 'Confirmar',
        action: _vm.validateIfHasTypeSelected,
        isVisible: true,
        isDisabled: !_vm.selectedIntegrator
      },"extra-button":{
        text: 'Cancelar',
        action: _vm.closeDialog,
        isVisible: true
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }